import { WppButton, WppCard, WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useIntegrationsApi } from 'api/tenant/queries/useIntegrationsApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { WrikeSettingsLogo } from 'components/svg/WrikeSettingsLogo'
import styles from 'pages/settings/integrations/Integrations.module.scss'

export const IntegrationsTab = () => {
  const { t } = useTranslation()
  const { data: integrations, isLoading: isIntegrationsLoading } = useIntegrationsApi({ staleTime: 60 * 1000 })

  const availableIntegrations = useMemo(() => integrations.filter(({ available }) => available), [integrations])

  //all integrations have available = false
  const isEmpty = !integrations || integrations.length === 0 || availableIntegrations.length === 0

  const currentUrl = window.location.href

  const urlObj = new URL(currentUrl)

  // Get the origin which includes the protocol and the domain
  const rootPath = urlObj.origin

  const navigateToIntegrations = () => {
    window.location.href = `${rootPath}/admin/integrations`
  }

  const capitalizeFirstLetter = (word: string) => {
    if (!word) return word
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  }

  if (isIntegrationsLoading) {
    return (
      <div className={styles.container}>
        {Array.from({ length: 1 }).map((_, index) => (
          <WppSkeleton key={index} variant="rectangle" height="194px" />
        ))}
      </div>
    )
  }

  if (isEmpty) {
    return (
      <Flex direction="column" align="center" justify="center" className={styles.emptyState}>
        <EmptyState
          title={t('dashboard.settings.integrations_empty_title')}
          description={t('dashboard.settings.integrations_empty_description')}
        >
          <WppButton variant="secondary" size="m" onClick={navigateToIntegrations} data-testid="confirm-btn">
            {t('dashboard.settings.integrations_empty_button')}
          </WppButton>
        </EmptyState>
      </Flex>
    )
  }

  return (
    <div className={styles.listContainer}>
      {availableIntegrations.map(({ type }) => (
        <WppCard key={type} className={styles.card} size="l">
          <Flex direction="column" gap={20}>
            <WrikeSettingsLogo className={styles.logoWrapper} />
            <WppTypography type="m-strong">{capitalizeFirstLetter(type)}</WppTypography>
          </Flex>
        </WppCard>
      ))}
    </div>
  )
}
