import { InputChangeEventDetail, SelectChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppInputCustomEvent, WppSelectCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppActionButton,
  WppCard,
  WppDivider,
  WppFilterButton,
  WppIconExternalLink,
  WppIconEyeOn,
  WppIconFolder,
  WppIconLink,
  WppIconMore,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppSelect,
  WppSkeleton,
  WppSpinner,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { DefaultHierarchyLevelType } from '@wpp-open/core/types/tenant'
import { useOs } from '@wpp-open/react'
import { RowClickedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'

import { usePatchTaskApi } from 'api/canvas/mutation/usePatchTaskApi'
import { useFetchWrikeWorkflows } from 'api/projects/queries/useFetchWrikeWorkflows'
import { useProjectAttributesApi } from 'api/projects/queries/useProjectAttributesApi'
import { useProjectInfiniteApi } from 'api/projects/queries/useProjectInfiniteApi'
import { useFetchTenantPreferenceApi } from 'api/tenant/queries/useFetchTenantPreferenceApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, tableActions } from 'components/common/table'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { TablePageInfinite } from 'components/common/table/tableInfinite/TablePageInfinite'
import { Truncate } from 'components/common/truncate/Truncate'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useFilters } from 'hooks/useFilters'
import { useHierarchy } from 'hooks/useHierarchy'
import { useStableCallback } from 'hooks/useStableCallback'
import { useToast } from 'hooks/useToast'
import { DashboardNavigation } from 'pages/dashboard/components/dashboardNavigation/DashboardNavigation'
import styles from 'pages/dashboard/components/tasksDashboard/TasksDashboard.module.scss'
import { useTasksListLoader } from 'pages/dashboard/components/tasksDashboard/useTasksListLoader'
import { TenantFilterSelect } from 'pages/dashboard/components/tenantFilterSelect/TenantFilterSelect'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { TaskPriorityChangeDropdown } from 'pages/project/components/tasks/components/priority/TaskPriorityChangeDropdown'
import { TaskPriorityIndicator } from 'pages/project/components/tasks/components/priority/TaskPriorityIndicator'
import { TaskStatusFilter } from 'pages/project/components/tasks/components/statusFilter/TaskStatusFilter'
import { showTasksFiltersModal } from 'pages/project/components/tasks/components/tasksFilters/TasksFiltersModal'
import { allStatuses } from 'pages/project/components/tasks/utils'
import { useOpenDashboardTaskPreviewModal } from 'pages/project/hooks/useOpenDashboardTaskPreviewModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { LocalStorageKey } from 'types/common/localStorage'
import { DetailsModalType } from 'types/common/utils'
import { TaskDashboard } from 'types/dashboard/tasksDashboard'
import { ProjectAttribute, ProjectAttributeClass, ProjectAttributeType } from 'types/projects/attributes'
import { ProjectStatus } from 'types/projects/projects'
import { TaskPriority, TasksFilter, TaskStatus } from 'types/projects/tasks'
import { hasClosestInteractiveElement } from 'utils/dom'
import { routesManager } from 'utils/routesManager'

export const FiltersSkeleton = () => (
  <Flex direction="row" gap={16} className={styles.skeletonContainer}>
    <WppSkeleton variant="rectangle" height="100%" width="300px" />
    <WppSkeleton variant="rectangle" height="100%" width="240px" />
    <WppSkeleton variant="rectangle" height="100%" width="99px" />
  </Flex>
)

export const initialTasksFilters: TasksFilter = {
  search: '',
  dueDateRanges: [],
  selectedProjects: [],
  tenant: [],
  selectedStatuses: [TaskStatus.IN_PROGRESS, TaskStatus.TO_DO],
}

const filtersCount = ({
  search,
  dueDateRanges,
  selectedProjects,
  workspace,
  tenant,
  selectedStatuses,
  attributes,
  includeEmptyWorkspace,
  ...hierarchy
}: TasksFilter) => {
  const restCount = Object.values({ ...hierarchy, ...attributes }).reduce(
    (acc, curr) => acc + Number(!!curr?.length),
    0,
  )

  return Number(!!(dueDateRanges || [])?.length) + restCount
}

const CACHE_TIME = 10 * 60 * 1000

export const TasksDashboardPage = () => {
  const { isLoading: isFiltersLoading, state: filtersState } = useFilters({
    initState: initialTasksFilters,
    lsCode: LocalStorageKey.DASHBOARD_TASKS_FILTERS,
  })

  const { data: attributes, isLoading: isAttributesLoading } = useProjectAttributesApi({ staleTime: CACHE_TIME })

  if (isFiltersLoading || isAttributesLoading)
    return (
      <Flex justify="center" align="center" className={styles.spinner}>
        <WppSpinner size="l" />
      </Flex>
    )

  return <TasksDashboard filtersState={filtersState} attributes={attributes} />
}

const TasksDashboard = ({
  filtersState,
  attributes,
}: {
  filtersState?: TasksFilter
  attributes?: ProjectAttribute[]
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  useOpenDashboardTaskPreviewModal()
  const [, copyToClipboard] = useCopyToClipboard()
  const { hierarchyOrder } = useHierarchy()
  const [, setSearchParams] = useSearchParams()
  const [isEmpty, setIsEmpty] = useState(false)
  const [projectsExist, setProjectsExist] = useState(false)
  const { showToast } = useToast()
  const [isTableLoading, setIsTableLoading] = useState(true)
  const [, setHideSubHeader] = useState(false)
  const projectFilterRef = useRef<HTMLWppSelectElement>(null)
  const {
    osContext: { userDetails, tenant },
  } = useOs()

  const isAgencyWorkspace = tenant.tenantType === TenantType.Agency
  const isClientTenant = tenant.tenantType === TenantType.Client

  const customSelectAttributes =
    attributes?.filter(
      attribute =>
        attribute.classification === ProjectAttributeClass.CUSTOM &&
        [ProjectAttributeType.MULTI_SELECT, ProjectAttributeType.SINGLE_SELECT].includes(attribute.type),
    ) || []

  const [filters, setFilters] = useState<TasksFilter>({
    ...initialTasksFilters,
    ...hierarchyOrder.reduce(
      (pre, curr) => ({
        ...pre,
        [curr]: [],
      }),
      {},
    ),
    attributes: customSelectAttributes?.reduce((acc, curr) => ({ ...acc, [curr.contractName]: [] }), {}),
    ...filtersState,
  })

  const [projectsFilterSearch, setProjectsFilterSearch] = useState('')
  const {
    data: projects,
    isLoading: isProjectsLoading,
    hasNextPage,
    fetchNextPage,
  } = useProjectInfiniteApi({
    params: {
      search: projectsFilterSearch.trim().length >= 2 ? projectsFilterSearch.trim() : undefined,
      type: [],
      // "My Tasks" page  contains only tasks from active projects
      status: [ProjectStatus.TO_DO, ProjectStatus.IN_PROGRESS],
      tenant: filters.tenant,
    },
  })

  const { data: tenantPreference } = useFetchTenantPreferenceApi()

  const fromCurrentTenant = useCallback((id: string) => tenant.id === id, [tenant])

  const { data: wrikeWorkflows } = useFetchWrikeWorkflows({
    enabled: tenantPreference?.useExternalStatuses,
  })

  const { mutateAsync: handleUpdateTask } = usePatchTaskApi()
  const { loader } = useTasksListLoader({ filters })

  const filtersCounts = useMemo(() => filtersCount(filters), [filters])

  useEffect(() => {
    const { search, ...rest } = filters
    localStorage.setItem(`dstasks:${userDetails.id}`, JSON.stringify(rest))
  }, [filters, userDetails.id])

  const updateStatus = useCallback(
    async (id: string, status: string) => {
      await handleUpdateTask({
        id,
        [tenantPreference?.useExternalStatuses ? 'externalStatusId' : 'status']: status,
      })

      await queryClient.invalidateQueries([ApiQueryKeys.TASKS_FETCHER])
      tableActions.reload([TableKey.TASKS_LIST])
    },
    [handleUpdateTask, tenantPreference?.useExternalStatuses],
  )

  const updatePriority = useCallback(
    async (id: string, priority: TaskPriority) => {
      try {
        await handleUpdateTask({
          id,
          priority,
        })
        await queryClient.invalidateQueries([ApiQueryKeys.TASKS_FETCHER])
        tableActions.reload([TableKey.TASKS_LIST])
      } catch (error) {
        showToast({
          type: 'error',
          message: t('common.generic_error'),
        })
        console.error(error)
      }
    },
    [handleUpdateTask, showToast, t],
  )

  const copyToClipboardAction = useCallback(
    (data: TaskDashboard) => {
      const origin = fromCurrentTenant(data.tenant?.id!)
        ? window.location.href
        : `${data.tenant?.homeUrl!}/orchestration${routesManager.tasks.root.getURL()}`

      copyToClipboard(
        `${origin}?${createSearchParams({
          view: DetailsModalType.DASHBOARD_TASK_DETAILS_PREVIEW,
          id: data.id!,
        })}`,
      )
      showToast({ type: 'success', message: t('project.tasks.toast_copy_to_clipboard_success_message')! })
    },
    [copyToClipboard, fromCurrentTenant, showToast, t],
  )

  const openTaskModal = useCallback(
    (data: TaskDashboard) => {
      if (!fromCurrentTenant(data.tenant!.id)) {
        window.open(
          `${data!.tenant!.homeUrl}/orchestration${routesManager.tasks.root.getURL()}?view=${
            DetailsModalType.DASHBOARD_TASK_DETAILS_PREVIEW
          }&id=${data!.id}`,
          '_blank',
        )
        return
      }

      setSearchParams({ view: DetailsModalType.DASHBOARD_TASK_DETAILS_PREVIEW, id: data.id })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const openTask = useCallback(
    (data: TaskDashboard) => {
      if (!fromCurrentTenant(data?.tenant?.id!)) {
        window.open(
          `${data!.tenant!.homeUrl}/orchestration${routesManager.project.tasks.getURL({ id: data!.projectId })}`,
          '_blank',
        )
        return
      }

      navigate(routesManager.project.tasks.getURL({ id: data!.projectId }))
    },
    [fromCurrentTenant, navigate],
  )

  const externalStatuses = useMemo(
    () =>
      wrikeWorkflows?.customStatuses?.map(({ wrikeId, name }) => ({
        value: wrikeId,
        label: name,
      })),
    [wrikeWorkflows?.customStatuses],
  )

  const setSearchDebounced = useDebounceFn((e: WppInputCustomEvent<InputChangeEventDetail>) => {
    const search = e.detail.value || ''
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setFilters(filters => ({ ...filters, search: searchQuery }))
  }, 300)

  const handleCloseTasksFilterModal = (newFilter: TasksFilter) => {
    setFilters(filters => ({ ...filters, ...newFilter }))
  }

  const noRowsOverlayComponent = useCallback(() => {
    return (
      <EmptyState
        title={isEmpty ? t('tasksDashboard.table.empty_state_no_tasks') : t('common.no_search_results')}
        testToken="tasks"
        filtersApplied={!isEmpty}
        description={
          isEmpty ? t('tasksDashboard.table.empty_state_no_tasks_description') : t('common.no_results_description')
        }
      />
    )
  }, [isEmpty, t])

  const gridOptions = {
    rowStyle: { cursor: 'pointer' },
  }

  const columnDefs = useMemo<ColDef<TaskDashboard>[]>(() => {
    return [
      {
        colId: 'task_name',
        flex: 2.5,
        minWidth: 250,
        headerName: t('tasksDashboard.table.task_name')!,
        cellRenderer: ({ data }) => (
          <WppTypography className={styles.overflow} type="s-body" title={data!.name} data-testid="task_name">
            {data!.name}
          </WppTypography>
        ),
      },
      {
        colId: 'project_name',
        flex: 1.75,
        minWidth: 175,
        headerName: t('tasksDashboard.table.project_name')!,
        cellRenderer: ({ data }) => (
          <WppTypography className={styles.overflow} type="s-body" title={data!.projectName} data-testid="project_name">
            {data!.projectName}
          </WppTypography>
        ),
      },
      {
        colId: 'tenant.id',
        flex: 1,
        hide: !isAgencyWorkspace,
        headerName: 'Workspace',
        cellRenderer: ({ data }) => (
          <Flex gap={4} align="center">
            <WppTypography type="s-body" className={styles.projectTenantName}>
              {data?.tenant?.name}
            </WppTypography>
            {!fromCurrentTenant(data?.tenant?.id!) && <WppIconExternalLink />}
          </Flex>
        ),
      },
      {
        colId: 'status',
        flex: 1.5,
        minWidth: 150,
        headerName: t('tasksDashboard.table.status')!,
        valueFormatter: ({ data }) =>
          tenantPreference?.useExternalStatuses && data?.wrike?.externalStatus
            ? data?.wrike?.externalStatus
            : data?.status
            ? t(`project.tasks.status.${data?.status}`)
            : '',
      },
      {
        colId: 'dueDate',
        flex: 1.5,
        minWidth: 150,
        headerName: t('tasksDashboard.table.due_date')!,
        cellRenderer: ({ data }) => <Calendar startDate={data?.startDate} endDate={data?.endDate} size="s-body" />,
      },
      ...hierarchyOrder.reduce((prev, curr) => {
        if (isClientTenant && curr === DefaultHierarchyLevelType.Client.toLowerCase()) return prev
        return [
          ...prev,
          {
            colId: curr,
            flex: 1,
            minWidth: 100,
            hide: isAgencyWorkspace,
            headerName: t(`modals.create_project.field_${curr}_label`, { defaultValue: curr })!,
            cellRenderer: ({ data }) => {
              const hierarchy = data?.projectContextHierarchy?.find(h => h.title.toLowerCase() === curr.toLowerCase())

              return (
                <Truncate type="s-body" className={styles.overflow} title={hierarchy?.name || ''}>
                  {hierarchy?.name || '-'}
                </Truncate>
              )
            },
          },
        ]
      }, [] as ColDef<TaskDashboard>[]),
      {
        colId: 'priority',
        flex: 1,
        minWidth: 100,
        headerName: t('tasksDashboard.table.priority')!,
        cellRenderer: ({ data }) => <TaskPriorityIndicator priority={data?.priority} />,
      },
      {
        width: 64,
        minWidth: 64,
        resizable: false,
        pinned: 'right',
        colId: 'actions',
        cellRenderer: ({ data }) => (
          <WppMenuContext
            className={styles.rowContextMenu}
            slot="actions"
            dropdownConfig={{
              appendTo: () => document.body,
              placement: 'bottom-end',
            }}
          >
            <WppActionButton slot="trigger-element" variant="secondary">
              <WppIconMore slot="icon-start" direction="horizontal" />
            </WppActionButton>
            <Flex direction="column" gap={4}>
              <WppMenuContext>
                <WppListItem slot="trigger-element" isExtended>
                  <WppTypography slot="label" type="s-body" data-testid="copy-link">
                    {t('project.tasks.status_action')}
                  </WppTypography>
                </WppListItem>
                {fromCurrentTenant(data!.tenant?.id!) &&
                  (data?.projectStatus === ProjectStatus.TO_DO ||
                    data?.projectStatus === ProjectStatus.IN_PROGRESS) && (
                    <TaskStatusChangeDropdown
                      onChange={status => updateStatus(data!.id, status)}
                      selectedStatus={data.wrike ? data.wrike!.externalStatusId! : data?.status}
                      showConfirm={false}
                      externalStatuses={data.wrike ? externalStatuses : undefined}
                      useExternalStatuses={tenantPreference?.useExternalStatuses}
                    />
                  )}
              </WppMenuContext>

              <WppMenuContext>
                <WppListItem slot="trigger-element" isExtended>
                  <WppTypography slot="label" type="s-body" data-testid="copy-link">
                    {t('project.tasks.priority_action')}
                  </WppTypography>
                </WppListItem>
                {(data?.projectStatus === ProjectStatus.TO_DO || data?.projectStatus === ProjectStatus.IN_PROGRESS) && (
                  <TaskPriorityChangeDropdown
                    onChange={priority => updatePriority(data!.id, priority)}
                    selectedPriority={data?.priority}
                    hideLabel
                  />
                )}
              </WppMenuContext>

              <WppDivider />

              <WppListItem onWppChangeListItem={() => openTaskModal(data!)} data-testid="task-details-context-action">
                <WppIconEyeOn slot="left" />
                <WppTypography slot="label" type="s-body">
                  {t('tasksDashboard.task.view_details_action')}
                </WppTypography>
              </WppListItem>
              <WppListItem onWppChangeListItem={() => openTask(data!)} data-testid="open-project-context-action">
                <WppIconFolder slot="left" />
                <WppTypography slot="label" type="s-body">
                  {t('tasksDashboard.task.open_project_action')}
                </WppTypography>
              </WppListItem>
              <WppListItem
                onWppChangeListItem={() => copyToClipboardAction(data!)}
                data-testid="copy-task-link-context-action"
              >
                <WppIconLink slot="left" />
                <WppTypography slot="label" type="s-body">
                  {t('tasksDashboard.task.copy_task_link_action')}
                </WppTypography>
              </WppListItem>
            </Flex>
          </WppMenuContext>
        ),
      },
    ]
  }, [
    copyToClipboardAction,
    externalStatuses,
    fromCurrentTenant,
    hierarchyOrder,
    isAgencyWorkspace,
    isClientTenant,
    openTask,
    openTaskModal,
    t,
    tenantPreference?.useExternalStatuses,
    updatePriority,
    updateStatus,
  ])

  const handleOnRowClicked = useStableCallback(({ event, data }: RowClickedEvent<TaskDashboard>) => {
    const target = event?.target as HTMLElement
    if (data && !hasClosestInteractiveElement(target)) openTaskModal(data)
  })

  const handleWorkspaceFilterChange = useCallback((e: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    // as projects is dependent on tenant, we need to reset it when tenant changes
    setFilters(filters => ({ ...filters, tenant: e.detail.value, selectedProjects: [] }))

    // bug on the CL, updating the projects on the filter change is not clear selected values properly
    ;(projectFilterRef?.current as any).handleClearAll()
  }, [])

  return (
    <Flex direction="column" className={styles.container}>
      <PageBackToTop scrollTopOffset={120} onChangeState={setHideSubHeader} />
      <DashboardNavigation />

      <div className={clsx(styles.viewContainer, { [styles.viewContainerEmpty]: !projectsExist })}>
        {!isEmpty && (
          <Flex className={styles.filtersContainer}>
            {isTableLoading ? (
              <FiltersSkeleton />
            ) : (
              <Flex gap={16}>
                <WppInput
                  size="s"
                  name="search"
                  placeholder={t('dashboard.field_search_placeholder')!}
                  onWppChange={setSearchDebounced}
                  type="search"
                  data-testid="dashboard-tasks-search"
                  className={styles.searchInput}
                />

                <TenantFilterSelect tenantIds={filters.tenant ?? []} onChange={handleWorkspaceFilterChange} />

                <WppSelect
                  ref={projectFilterRef}
                  size="s"
                  placeholder={t('tasksDashboard.project_select_placeholder')}
                  className={styles.select}
                  value={filters.selectedProjects}
                  onWppChange={e => setFilters(filters => ({ ...filters, selectedProjects: e.detail.value }))}
                  type="multiple"
                  locales={{
                    allSelectedText: t('tasksDashboard.all_projects_selected'),
                    clearAllText: t('common.clear_all'),
                    selectAllText: t('common.select_all'),
                    selectLabel: t('common.selected_label'),
                  }}
                  withSearch
                  infinite
                  loading={isProjectsLoading}
                  infiniteLastPage={!hasNextPage}
                  loadMore={fetchNextPage as () => Promise<any>}
                  onWppSearchValueChange={(e: CustomEvent) => setProjectsFilterSearch(e.detail)}
                  data-testid="projects-select"
                >
                  {projects.map(({ id, name }) => (
                    <WppListItem key={id} value={id}>
                      <p slot="label">{name}</p>
                    </WppListItem>
                  ))}
                </WppSelect>

                <TaskStatusFilter
                  useExternalStatuses={false}
                  filters={filters}
                  setFilters={setFilters}
                  statusesOptions={allStatuses}
                />

                <WppFilterButton
                  counter={filtersCounts}
                  onClick={() =>
                    showTasksFiltersModal({
                      filters,
                      onFiltersSave: handleCloseTasksFilterModal,
                      withHierarchy: true,
                      attributes: customSelectAttributes,
                    })
                  }
                  data-testid="tasks-filter-button"
                >
                  {t('dashboard.btn_filter')!}
                </WppFilterButton>
              </Flex>
            )}
          </Flex>
        )}
        <WppCard className={clsx(styles.card, { [styles.emptyCard]: !isTableLoading && !projectsExist })}>
          <TablePageInfinite
            gridOptions={gridOptions}
            tableKey={TableKey.TASKS_LIST}
            loader={loader}
            cacheBlockSize={50}
            rowHeight={48}
            columnDefs={columnDefs}
            onRowClicked={handleOnRowClicked}
            noRowsOverlayComponent={noRowsOverlayComponent}
            headerHeight={isEmpty ? 0 : undefined}
            onLoadSuccess={({ isEmptySource }) => {
              setIsTableLoading(false)
              setProjectsExist(!isEmptySource)
              setIsEmpty(
                isEmptySource &&
                  !filters?.search &&
                  !filtersCounts &&
                  !filters?.selectedProjects?.length &&
                  !filters?.selectedStatuses?.length,
              )
            }}
            className={clsx({ [styles.hideBorder]: isEmpty })}
          />
        </WppCard>
      </div>
    </Flex>
  )
}
