// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WSeuG{display:grid;grid-auto-rows:1fr;grid-auto-columns:1fr;grid-auto-flow:row;grid-template-columns:1fr 1fr 1fr;gap:28px 28px;margin-top:24px}.W7kLs{color:var(--wpp-grey-color-1000)}.CmBkv{flex-grow:1;width:100%;max-width:1920px;margin:0 auto}.XPd_X{height:194px}.kVPxD{border-radius:8px}.OIcFH{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/settings/integrations/Integrations.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,kBAAA,CACA,iCAAA,CACA,aAAA,CACA,eAAA,CAGF,OACE,gCAAA,CAGF,OACE,WAAA,CACA,UAAA,CACA,gBAAA,CACA,aAAA,CAGF,OACE,YAAA,CAGF,OACE,iBAAA,CAGF,OACE,UAAA","sourcesContent":[".listContainer {\n  display: grid;\n  grid-auto-rows: 1fr;\n  grid-auto-columns: 1fr;\n  grid-auto-flow: row;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 28px 28px;\n  margin-top: 24px;\n}\n\n.greyColor1000 {\n  color: var(--wpp-grey-color-1000);\n}\n\n.emptyState {\n  flex-grow: 1;\n  width: 100%;\n  max-width: 1920px;\n  margin: 0 auto;\n}\n\n.card {\n  height: 194px;\n}\n\n.logoWrapper {\n  border-radius: 8px;\n}\n\n.nav {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": `WSeuG`,
	"greyColor1000": `W7kLs`,
	"emptyState": `CmBkv`,
	"card": `XPd_X`,
	"logoWrapper": `kVPxD`,
	"nav": `OIcFH`
};
export default ___CSS_LOADER_EXPORT___;
